import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import {
  Box, Button, Grid, LoadingBubbles, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

import useCurrentUser from '@hooks/useCurrentUser'
import { getTimeIntervals } from '@functions/getTimeIntervals'

import CalendarGrid from './components/CalendarGrid'
import ConfirmBooking from './components/ConfirmBooking'
import Header from './components/Header'
import TimeSlots from './components/TimeSlots'

import styles from './styles.module.scss'

const defaultState = {
  calendarServiceGroups: [],
  displayedTime: [],
  timeSlots: [],
}

const Calendar = () => {
  const [state, setState] = useSetState(defaultState)
  const { calendarServiceGroups, displayedTime, timeSlots } = state

  const {
    calendarDates, calendarSettings: { endTime, slotLength, startTime }, callbacks, campaignServiceJobs, currentUserV2, selectedWeek,
  } = useContext(PageContext)
  const { toggleUpdateSelectedWeek } = callbacks

  const { currentUser: { timeZone } } = useCurrentUser()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroupsV2 } = entities

  const { loading } = useSelector(reduxState => reduxState.availableSlot)

  const getCalendarServiceGroups = () => {
    const services = campaignServiceJobs.map(serviceJob => serviceGroupsV2[serviceJob.serviceGroupId]).filter(service => service !== undefined)

    return [...new Set(services)].sort((x, y) => x.id - y.id)
  }

  useEffect(() => {
    setState({
      displayedTime: getTimeIntervals(30, startTime, endTime, timeZone),
      timeSlots: getTimeIntervals(15, startTime, endTime, timeZone),
    })
  }, [timeZone])

  useEffect(() => {
    setState({
      calendarServiceGroups: getCalendarServiceGroups(),
    })
  }, [campaignServiceJobs, serviceGroupsV2])

  return (
    <>
      <LoadingModule loading={loading} />
      {!loading && (
        <Box className={styles.root} flexDirection="column">
          <Box
            backgroundColor="white"
            border="1px solid"
            borderColor="lineColor"
            borderRadius={5}
            flexDirection="column"
            marginBottom="large"
            width="100%"
          >
            <Box borderBottom="1px solid" borderColor="lineColor" padding="large" width="auto" alignItems="center">
              <Box alignItems="center" justifyContent="flex-start" marginRight="auto" width="auto">
                {selectedWeek.id > 0 && (
                  <Button
                    border={0}
                    buttonStyle="secondary"
                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    onClick={() => toggleUpdateSelectedWeek(selectedWeek.id - 1)}
                    padding="0!important"
                    size="small"
                  />
                )}
              </Box>
              {calendarDates.length < 1 ? <LoadingBubbles color="faintGrey" /> : (
                <Text letterSpacing="0.5px" fontSize="small" width="auto">
                  {DateTime.fromISO(calendarDates[0], { zone: timeZone }).toFormat('LLLL dd, y').toUpperCase()}
                  {calendarDates.length > 1 ? ` - ${DateTime.fromISO(calendarDates[calendarDates.length - 1], { zone: timeZone }).toFormat('LLLL dd, y').toUpperCase()}` : ''}
                </Text>
              )}
              <Box alignItems="center" justifyContent="flex-end" marginLeft="auto" width="auto">
                {selectedWeek.id < 8 && (
                  <Button
                    border={0}
                    buttonStyle="secondary"
                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                    onClick={() => toggleUpdateSelectedWeek(selectedWeek.id + 1)}
                    padding="0!important"
                    size="small"
                  />
                )}
              </Box>
            </Box>
            <Grid
              gridTemplateColumns="75px auto"
              gridTemplateRows="auto"
              gridTemplateAreas="
                'header-space header'
                'timeslots-container main'"
              height="600px"
              overflow="auto"
            >
              <Grid
                backgroundColor="white"
                gridArea="header-space"
                left="0"
                position="sticky"
                style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                top="0"
                zIndex="7"
              />
              <Header calendarServiceGroups={calendarServiceGroups} dates={calendarDates} />
              <TimeSlots displayedTime={displayedTime} slotLength={slotLength} timeZone={timeZone} />
              <CalendarGrid calendarServiceGroups={calendarServiceGroups} timeSlots={timeSlots} />
            </Grid>
          </Box>
          {!currentUserV2?.useCHOrders && <ConfirmBooking />}
        </Box>
      )}
    </>
  )
}

export default Calendar
