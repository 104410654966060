import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import { Text } from '@campaignhub/suit-theme'

import { clientUrl } from '@functions/api'

const Redirect = () => {
  const { platform } = useParams()

  const {
    projectref, orderref, orderitemref, override, page,
  } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'projectref',
    'orderref',
    'orderitemref',
    'override',
    'page',
  ])

  useEffect(() => {
    let searchParams = projectref ? `?projectref=${projectref}` : ''
    if (orderref) searchParams = searchParams.length ? `${searchParams}&orderref=${orderref}` : `?orderref=${orderref}`
    if (orderitemref) searchParams = searchParams.length ? `${searchParams}&orderitemref=${orderitemref}` : `?orderitemref=${orderitemref}`
    if (override) searchParams = searchParams.length ? `${searchParams}&override=${override}` : `?override=${override}`
    if (page) searchParams = searchParams.length ? `${searchParams}&page=${page}` : `?page=${page}`
    window.location.href = `${clientUrl}/redirect/${platform.toLowerCase()}${searchParams}`
  }, [projectref, orderref, orderitemref, override])

  return (
    <Text color="bodyFontLightColor">
      Redirecting...
    </Text>
  )
}

export default Redirect
